/*!

Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the terms of the LICENSE file distributed with this project.

*/
@font-face{
  font-family:"Icons16";
  font-weight:normal;
  font-style:normal;
  src:url("../../resources/icons/icons-16.eot?#iefix") format("embedded-opentype"), url("../../resources/icons/icons-16.woff") format("woff"), url("../../resources/icons/icons-16.ttf") format("truetype"); }

@font-face{
  font-family:"Icons20";
  font-weight:normal;
  font-style:normal;
  src:url("../../resources/icons/icons-20.eot?#iefix") format("embedded-opentype"), url("../../resources/icons/icons-20.woff") format("woff"), url("../../resources/icons/icons-20.ttf") format("truetype"); }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9ibHVlcHJpbnQtaWNvbnMuc2NzcyIsIi4uLy4uL3NyYy9fZm9udC1mYWNlLnNjc3MiLCIuLi8uLi9zcmMvX2ZvbnQtaW1wb3J0cy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOzs7OztFQUtFO0FDNkJBO0VBQ0Usc0JDM0J3QjtFRDRCeEIsbUJBTGtCO0VBTWxCLGtCQUxpQjtFQU9qQiwwTUFpQjZDLEVBQUE7O0FBdEIvQztFQUNFLHNCQzFCd0I7RUQyQnhCLG1CQUxrQjtFQU1sQixrQkFMaUI7RUFPakIsME1BaUI2QyxFQUFBIiwiZmlsZSI6ImJsdWVwcmludC1pY29ucy5jc3MifQ== */